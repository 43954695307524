import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, EMPTY, from, switchMap, throwError } from 'rxjs';
import { Endpoints } from '../app-state/app.models';
import { AppState } from '../app-state/app.state';
import { ClearAuth } from './auth.actions';
import { AuthRoutes } from './auth.routes';
import { AuthState } from './auth.state';

export const tokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const store = inject(Store);
  if (!isProtected(request.url, store)) return next(request);

  const router = inject(Router);
  const token = store.selectSnapshot(AuthState.accessToken);

  request = request.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });

  return next(request).pipe(
    catchError((err: unknown) => {
      if (isUnauthorized(err)) {
        return store.dispatch(ClearAuth).pipe(
          switchMap(() => from(router.navigate([AuthRoutes.Login]))),
          switchMap(() => EMPTY),
        );
      }

      if (isForbidden(err)) {
        return from(router.navigate([AuthRoutes.Unauthorized])).pipe(switchMap(() => EMPTY));
      }

      return throwError(() => err);
    }),
  );
};

function isProtected(url: string, store: Store) {
  const endpoints = store.selectSnapshot(AppState.endpoints);
  for (const key in endpoints) {
    const endpoint = endpoints[key as keyof Endpoints];
    if (url.startsWith(endpoint.url)) return endpoint.protected;
  }
  return false;
}

function isUnauthorized(err: unknown) {
  return err instanceof HttpErrorResponse && err.status === HttpStatusCode.Unauthorized;
}

function isForbidden(err: unknown) {
  return err instanceof HttpErrorResponse && err.status === HttpStatusCode.Forbidden;
}

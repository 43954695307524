import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { map } from 'rxjs';
import { RegionState } from './region.state';

/**
 * Redirects to a default region if no region code is provided in the route.
 * Continues navigation if the region code in the path is found in state.
 */
export const regionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const store = inject(Store);
  const router = inject(Router);

  const regionCodeRoute = route.children.length > 0 ? route.children[0] : null;
  const regionCode = regionCodeRoute?.paramMap.get('regionCode');

  const regions = store.selectSnapshot(RegionState.regions);
  const region = regions?.find((region) => region.regionCode === regionCode);

  if (isDefined(region)) return true;

  // no or invalid region code in path, get region from state and redirect
  return store.select(RegionState.activeRegion).pipe(
    map((region) => {
      if (isNil(region)) return false;

      return router.createUrlTree(['region', region.regionCode]);
    }),
  );
};

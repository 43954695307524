import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { BehaviorSubject, switchMap } from 'rxjs';
import { ClearReturnPath, SetReturnPath } from '../../auth.actions';
import { AuthRoutes } from '../../auth.routes';
import { AuthService } from '../../auth.service';
import { AuthState } from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'sda-login',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  templateUrl: 'login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  private _busy$ = new BehaviorSubject(true);
  private _error$ = new BehaviorSubject(false);

  busy$ = this._busy$.asObservable();
  error$ = this._error$.asObservable();

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit() {
    const code = this.activatedRoute.snapshot.queryParamMap.get('code');

    if (isNil(code)) {
      const returnPath = this.activatedRoute.snapshot.queryParamMap.get('returnPath') ?? '';

      // store the returnPath now, in case we're redirected to TID
      // if so, we need to retrieve it once redirected back to our app
      this.store
        .dispatch(new SetReturnPath(returnPath))
        .pipe(
          switchMap(() => this.authService.signIn()),
          untilDestroyed(this),
        )
        .subscribe({
          next: () => this.router.navigateByUrl(returnPath),
          error: () => this.handleError(),
        });
    }

    if (isDefined(code)) {
      const returnPath = this.store.selectSnapshot(AuthState.returnPath) ?? '';

      this.authService
        .codeTokenExchange(code)
        .pipe(
          switchMap((token) => this.authService.getUserInfo(token.id_token)),
          switchMap(() => this.store.dispatch(new ClearReturnPath())),
          untilDestroyed(this),
        )
        .subscribe({
          next: () => {
            history.replaceState(null, '', AuthRoutes.Login);
            this.router.navigateByUrl(returnPath);
          },
          error: () => this.handleError(),
        });
    }
  }

  private handleError() {
    history.replaceState(null, '', AuthRoutes.Login);
    this._busy$.next(false);
    this._error$.next(true);
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLinkActive, RouterLinkWithHref } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { NgxPermissionsModule } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { AppPermissions } from '../../../auth/auth.models';
import { RegionState } from '../../../region/region.state';

@UntilDestroy()
@Component({
  selector: 'sda-navigation',
  standalone: true,
  imports: [
    CommonModule,
    RouterLinkWithHref,
    RouterLinkActive,
    MatListModule,
    MatIconModule,
    NgxPermissionsModule,
    MatToolbarModule,
  ],
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  @Select(RegionState.activeRegionCode) regionCode$!: Observable<string | undefined>;

  permissions = AppPermissions;
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UntilDestroy } from '@ngneat/until-destroy';
import { take } from 'rxjs';
import { AuthService } from '../../auth.service';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [MatProgressSpinnerModule],
  templateUrl: './logout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.signOut().pipe(take(1)).subscribe();
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { map, of, switchMap, take } from 'rxjs';
import { AppState } from '../app-state/app.state';
import { WINDOW } from '../services/window.provider';
import { SetActiveRegion, SetRegions } from './region.actions';
import { Region } from './region.models';
import { RegionState } from './region.state';

interface RegionDto {
  sdsRegionCode: string;
  sdsBaseUrl: string;
  connectRegion: string;
}

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  private get regionsUrl() {
    return this.store.selectSnapshot(AppState.settings).endpoints['regions'].url;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private store: Store,
    private http: HttpClient,
  ) {}

  setDefaultActiveRegion() {
    return this.getRegions().pipe(
      switchMap((regions) => {
        if (regions.length === 0) throw new Error('No regions loaded');

        const region = this.getRegionFromRouteOrDefault(regions);

        return this.store.dispatch(new SetActiveRegion(region)).pipe(map(() => region));
      }),
      take(1),
    );
  }

  getRegions() {
    return this.store.select(RegionState.regions).pipe(
      switchMap((regions) => {
        return isDefined(regions) ? of(regions) : this.fetchAndCacheRegions();
      }),
    );
  }

  private getRegionFromRouteOrDefault(regions: Region[]) {
    const regionCode = this.getRegionCodeFromLocation();
    const region = regions.find((region) => region.regionCode === regionCode);

    if (isDefined(region)) return region;

    return [...regions].sort((a, b) => a.regionCode.localeCompare(b.regionCode))[0];
  }

  private getRegionCodeFromLocation() {
    const pathParts = this.window.location.pathname.split('/').filter((part) => part.length > 0);
    if (pathParts[0] === 'region' && pathParts.length > 1) return pathParts[1];

    return null;
  }

  private fetchAndCacheRegions() {
    return this.http.get<RegionDto[]>(this.regionsUrl).pipe(
      map((projectRegions) => {
        /**
         * Multiple connect regions could map to a single regionCode.
         * Here we get a distinct list of regions, grouped by regionCode.
         */
        const distinctRegions = projectRegions.reduce((acc: Region[], current) => {
          const region = acc.find((item) => item.regionCode === current.sdsRegionCode);
          if (isDefined(region)) {
            region.connectRegions.push(current.connectRegion);
            return acc;
          }

          acc.push({
            regionCode: current.sdsRegionCode,
            endpoint: { url: current.sdsBaseUrl, protected: true },
            connectRegions: [current.connectRegion],
          });

          return acc;
        }, []);

        return distinctRegions;
      }),
      switchMap((regions) => {
        return this.store.dispatch(new SetRegions(regions)).pipe(map(() => regions));
      }),
    );
  }
}

import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

export const enum AuthRoutes {
  Login = 'login',
  Logout = 'logout',
  Unauthorized = 'unauthorized',
}

export const AUTH_ROUTES: Routes = [
  { path: AuthRoutes.Login, component: LoginComponent },
  { path: AuthRoutes.Logout, component: LogoutComponent },
  {
    path: AuthRoutes.Unauthorized,
    component: UnauthorizedComponent,
  },
];

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs';
import { AuthRoutes } from '../auth/auth.routes';
import { SupportUserService } from './support-user.service';

export const supportUserGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const userRoleService = inject(SupportUserService);

  const regionCode = route.paramMap.get('regionCode')!;

  return userRoleService.hasRegionAccess(regionCode).pipe(
    map((hasRegionAccess) => {
      if (hasRegionAccess) return true;

      return router.createUrlTree([AuthRoutes.Unauthorized]);
    }),
  );
};

import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { RegionState, RegionStateModel } from '../region/region.state';
import { SupportUserRoleType } from './support-user-role-type';
import { SupportUser } from './support-user.model';

export interface SupportUserStateModel {
  [key: string]: SupportUser;
}

export class SetSupportUser {
  static readonly type = '[SupportUserState] SetSupportUser';
  constructor(
    public regionCode: string,
    public supportUser: SupportUser,
  ) {}
}

@State<SupportUserStateModel>({
  name: 'supportUserState',
  defaults: {},
})
@Injectable()
export class SupportUserState {
  static getSupportUser(regionCode: string): (state: SupportUserStateModel) => SupportUser {
    return createSelector([SupportUserState], (state: SupportUserStateModel) => {
      return state[regionCode];
    });
  }

  static hasUserRole(roleType: SupportUserRoleType) {
    return createSelector(
      [SupportUserState, RegionState],
      (supportUserState: SupportUserStateModel, regionState: RegionStateModel) => {
        const activeRegion = regionState.activeRegion;
        if (isNil(activeRegion)) return false;

        const supportUser = supportUserState[activeRegion.regionCode];
        if (isNil(supportUser)) return false;

        return isDefined(supportUser.roleClaims.find((role) => role === roleType)) ?? false;
      },
    );
  }

  @Action(SetSupportUser)
  setSupportUser(
    ctx: StateContext<SupportUserStateModel>,
    { regionCode, supportUser }: SetSupportUser,
  ) {
    ctx.patchState({ [regionCode]: supportUser });
  }
}

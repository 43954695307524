import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { provideStore } from '@ngxs/store';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppService } from './app-state/app.service';
import { AppState } from './app-state/app.state';
import { appRoutes } from './app.routes';
import { AuthState } from './auth/auth.state';
import { tokenInterceptor } from './auth/token.interceptor';
import { JobListState } from './jobs/components/job-list/job-list.state';
import { loadingInterceptor } from './layout/interceptors/loading.interceptor';
import { LayoutState } from './layout/layout.state';
import { RegionState } from './region/region.state';
import { RoleListState } from './roles/components/role-list/role-list.state';
import { ScanListState } from './scans/components/scan-list/scan-list.state';
import { windowProvider } from './services/window.provider';
import { SupportUserState } from './support-user/support-user.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes),
    provideHttpClient(withInterceptors([tokenInterceptor, loadingInterceptor])),
    provideStore(
      [
        AppState,
        RegionState,
        AuthState,
        SupportUserState,
        LayoutState,
        RoleListState,
        JobListState,
        ScanListState,
      ],
      withNgxsStoragePlugin({ keys: [AuthState, LayoutState] }),
    ),
    importProvidersFrom(NgxPermissionsModule.forRoot(), MatDialogModule),
    windowProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppService],
      multi: true,
    },
  ],
};

function initializeAppFactory(appService: AppService) {
  return () => appService.initialize();
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Observable, combineLatest, map } from 'rxjs';
import { UserInfo } from './auth/auth.models';
import { AuthState } from './auth/auth.state';
import { NavbarComponent } from './layout/components/navbar/navbar.component';
import { NavigationComponent } from './layout/components/navigation/navigation.component';
import { LayoutState } from './layout/layout.state';
import { SupportUserState } from './support-user/support-user.state';

export interface DataEntity {
  name: string;
}

@UntilDestroy()
@Component({
  selector: 'sda-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NavbarComponent,
    NavigationComponent,
    MatSidenavModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatTableModule,
    MatDialogModule,
  ],
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @Select(AuthState.user) user$!: Observable<UserInfo>;
  @Select(SupportUserState.hasUserRole('superuser')) isSuperUser$!: Observable<boolean>;
  @Select(AuthState.loggedIn) loggedIn$!: Observable<UserInfo>;
  @Select(LayoutState.loading) loading$!: Observable<boolean>;
  @Select(LayoutState.darkTheme) darkThemeOn$!: Observable<boolean>;

  showNavigation$!: Observable<boolean>;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.showNavigation$ = combineLatest([this.loggedIn$, this.user$, this.isSuperUser$]).pipe(
      map(([loggedIn, user, isSuperUser]) => {
        return (loggedIn ?? false) && isDefined(user) && isSuperUser;
      }),
    );
    this.subscribeThemeChange();
  }

  subscribeThemeChange() {
    this.darkThemeOn$.pipe(untilDestroyed(this)).subscribe((darkThemeOn) => {
      if (darkThemeOn) {
        this.renderer.removeClass(document.documentElement, 'light');
        this.renderer.addClass(document.documentElement, 'dark');
      } else {
        this.renderer.removeClass(document.documentElement, 'dark');
        this.renderer.addClass(document.documentElement, 'light');
      }
    });
  }
}

<button mat-button [matMenuTriggerFor]="regionSelect" [matTooltip]="'Select region'">
  <span class="flex items-center">
    @if (activeRegionOption$ | async; as activeRegion) {
      <span>
        {{ activeRegion.description }}
      </span>
    } @else {
      Select Region
    }
    <mat-icon>expand_more</mat-icon>
  </span>
</button>

<mat-menu #regionSelect class="region-select-menu">
  @for (region of regionOptions$ | async; track region.regionCode) {
    <button
      (click)="selectRegion(region)"
      class="flex w-full py-2 px-4"
      [ngClass]="{ selected: region.active }"
    >
      <span class="flex flex-row flex-1">
        <span class="flex flex-1">{{ region.description }}</span>
        @if (region.active) {
          <mat-icon class="check ml-4">check</mat-icon>
        }
      </span>
    </button>
  }
</mat-menu>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { buildUrl, isDefined } from '@trimble-gcs/common';
import { filter, map, of, switchMap, take } from 'rxjs';
import { AuthState } from '../auth/auth.state';
import { Region } from '../region/region.models';
import { RegionState } from '../region/region.state';
import { SupportUser } from './support-user.model';
import { SetSupportUser, SupportUserState } from './support-user.state';

@Injectable({
  providedIn: 'root',
})
export class SupportUserService {
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  loadAndCacheSupportUser(regionCode: string) {
    const loggedIn = this.store.selectSnapshot(AuthState.loggedIn);
    if (!loggedIn) return of(null);

    const region$ = this.store.select(RegionState.regions).pipe(
      filter(isDefined),
      take(1),
      map((regions) => regions.find((region) => region.regionCode === regionCode)!),
    );

    return region$.pipe(
      switchMap((region) => {
        const url = buildUrl(region.endpoint.url, 'support/me');

        return this.http
          .get<SupportUser>(url)
          .pipe(map((supportUser) => [region, supportUser] as [Region, SupportUser]));
      }),
      switchMap(([region, supportUser]) => {
        return this.store
          .dispatch(new SetSupportUser(region.regionCode, supportUser))
          .pipe(map(() => supportUser));
      }),
    );
  }

  hasRegionAccess(regionCode: string) {
    return this.getSupportUser(regionCode).pipe(
      map((superUser) => {
        return isDefined(superUser?.roleClaims.find((role) => role === 'superuser'));
      }),
    );
  }

  private getSupportUser(regionCode: string) {
    return this.store.selectOnce(SupportUserState.getSupportUser(regionCode)).pipe(
      switchMap((supportUser) => {
        return isDefined(supportUser) ? of(supportUser) : this.loadAndCacheSupportUser(regionCode);
      }),
    );
  }
}

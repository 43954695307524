<div class="flex flex-col h-full max-h-full">
  <sda-navbar></sda-navbar>

  <div class="mat-toolbar min-h-[2px] h-[2px]">
    @if (loading$ | async) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </div>

  <mat-sidenav-container class="grow">
    <mat-sidenav [mode]="'side'" [opened]="showNavigation$ | async" class="!w-[220px]">
      <sda-navigation class="min-h-full"></sda-navigation>
    </mat-sidenav>

    <mat-sidenav-content class="flex flex-col h-full pl-4 pr-4 pb-4">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<mat-toolbar>
  <div class="text-lg dark:text-white">Reality Capture Admin</div>

  <div class="flex border-l border-l-gray-2 p-1 ml-5">
    <sd-region-select></sd-region-select>
  </div>

  <div class="flex-auto"></div>

  @if (user$ | async; as user) {
    <div class="flex items-center">
      <span class="flex text-3xl mr-2">
        <mat-icon [inline]="true">account_circle</mat-icon>
      </span>
      <div class="flex flex-col">
        <div class="font-normal text-sm">{{ user.given_name }}</div>
        <div class="font-thin text-xs">{{ user.email }}</div>
      </div>
      <button
        mat-icon-button
        [matTooltip]="'Toggle dark mode'"
        class="ml-4 mr-2"
        (click)="toggleDarkMode()"
      >
        <mat-icon>{{ (darkTheme$ | async) ? 'sunny' : 'dark_mode' }}</mat-icon>
      </button>
      <a mat-icon-button [routerLink]="['logout']" [matTooltip]="'Sign out'" class="ml-4 mr-2">
        <mat-icon>logout</mat-icon>
      </a>
    </div>
  }
</mat-toolbar>

import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize, Subscription } from 'rxjs';
import { LayoutService } from '../layout.service';

export const loadingInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const layoutService = inject(LayoutService);

  let loaderSubscription: Subscription;

  //using promise to push change onto next change
  //detection cycle vs end of the current queue
  Promise.resolve(null).then(() => {
    loaderSubscription = layoutService.loader$.subscribe();
  });

  return next(request).pipe(
    finalize(() => {
      Promise.resolve(null).then(() => {
        loaderSubscription.unsubscribe();
      });
    }),
  );
};

<div class="min-h-full">
  <div class="pb-6">
    <mat-nav-list>
      <ng-container *ngxPermissionsOnly="[permissions.viewScans, permissions.viewJobs]">
        <div mat-subheader>Reports</div>

        @if (regionCode$ | async; as regionCode) {
          <a
            mat-list-item
            *ngxPermissionsOnly="[permissions.viewScans]"
            [routerLink]="['region', regionCode, 'scans']"
            routerLinkActive="active"
          >
            <mat-icon matListItemIcon>cloud</mat-icon>
            <span matListItemTitle>Scans</span>
          </a>

          <a
            mat-list-item
            *ngxPermissionsOnly="[permissions.viewJobs]"
            [routerLink]="['region', regionCode, 'jobs']"
            routerLinkActive="active"
          >
            <mat-icon matListItemIcon>engineering</mat-icon>
            <span matListItemTitle>Jobs</span>
          </a>
        }
      </ng-container>

      <ng-container *ngxPermissionsOnly="[permissions.viewRoles]">
        <div mat-subheader>Portal Management</div>

        <a
          mat-list-item
          *ngxPermissionsOnly="[permissions.viewRoles]"
          [routerLink]="['roles']"
          routerLinkActive="active"
        >
          <mat-icon matListItemIcon>lock</mat-icon>
          <span matListItemTitle>Roles</span>
        </a>
      </ng-container>
    </mat-nav-list>
  </div>
</div>

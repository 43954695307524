import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { from, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { RegionService } from '../region/region.service';
import { SupportUserService } from '../support-user/support-user.service';
import { SetSettings } from './app.actions';
import { AppSettings } from './app.models';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private store: Store,
    private authService: AuthService,
    private supportUserService: SupportUserService,
    private regionService: RegionService,
  ) {}

  initialize() {
    return from(fetch('appsettings.json')).pipe(
      switchMap((response) => from(response.json())),
      switchMap((settings: AppSettings) => this.store.dispatch(new SetSettings(settings))),
      switchMap(() => this.authService.registerTokenExpiryWatch()),
      switchMap(() => this.authService.loadUser()),
      switchMap(() => this.regionService.setDefaultActiveRegion()),
      switchMap((region) => this.supportUserService.loadAndCacheSupportUser(region.regionCode)),
    );
  }
}

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { AuthRoutes } from './auth.routes';
import { AuthState } from './auth.state';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(AuthState.loggedIn).pipe(
    map((loggedIn) => {
      if (loggedIn) return true;

      return router.createUrlTree([AuthRoutes.Login], { queryParams: { returnPath: state.url } });
    }),
  );
};

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLinkWithHref } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserInfo } from '../../../auth/auth.models';
import { AuthState } from '../../../auth/auth.state';
import { RegionSelectComponent } from '../../../region/region-select/region-select.component';
import { ToggleDarkTheme } from '../../layout.actions';
import { LayoutState } from '../../layout.state';

@UntilDestroy()
@Component({
  selector: 'sda-navbar',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatToolbarModule,
    MatMenuModule,
    MatListModule,
    RegionSelectComponent,
    RouterLinkWithHref,
  ],
  templateUrl: './navbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  @Select(AuthState.user) user$!: Observable<UserInfo>;
  @Select(LayoutState.darkTheme) darkTheme$!: Observable<boolean>;

  constructor(private store: Store) {}

  toggleDarkMode() {
    this.store.dispatch(new ToggleDarkTheme());
  }
}

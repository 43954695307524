import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest, combineLatestWith, filter, map, shareReplay } from 'rxjs';

import { Region } from '../region.models';
import { RegionService } from '../region.service';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { isDefined, isNil } from '@trimble-gcs/common';
import { SetActiveRegion } from '../region.actions';
import { RegionState } from '../region.state';

interface RegionOption extends Region {
  description: string;
  active: boolean;
}

@UntilDestroy()
@Component({
  selector: 'sd-region-select',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule, MatTooltipModule],
  templateUrl: './region-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegionSelectComponent implements OnInit {
  @Select(RegionState.activeRegion) private activeRegion$!: Observable<Region | undefined>;

  regionOptions$!: Observable<RegionOption[]>;
  activeRegionOption$!: Observable<RegionOption | undefined>;

  private regions$!: Observable<Region[]>;

  constructor(
    private store: Store,
    private regionService: RegionService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.regions$ = this.getRegions();

    this.regionOptions$ = combineLatest([this.regions$, this.activeRegion$]).pipe(
      map(([regions, activeRegion]) => this.mapRegionsToRegionOptions(regions, activeRegion)),
    );

    this.activeRegionOption$ = combineLatest([this.regionOptions$, this.activeRegion$]).pipe(
      map(([regions, activeRegion]) => {
        return regions.find((region) => region.regionCode === activeRegion?.regionCode);
      }),
    );

    this.subscribeToRouteChange();
  }

  selectRegion(region: Region) {
    const targetRoute = ['region', region.regionCode];

    const root = this.router.routerState.snapshot.root;
    const path = root.firstChild?.firstChild?.firstChild?.routeConfig?.path;
    if (isDefined(path) && path.length > 0) targetRoute.push(path);

    this.store
      .dispatch(new SetActiveRegion(region!))
      .subscribe(() => this.router.navigate(targetRoute));
  }

  private mapRegionsToRegionOptions(regions: Region[], activeRegion?: Region) {
    const regionOptions = regions.map((region) => {
      const active = region.regionCode === activeRegion?.regionCode;

      const regionOption: RegionOption = {
        ...region,
        description: `${region.regionCode} (${region.connectRegions.sort().join(', ')})`,
        active,
      };

      return regionOption;
    });

    regionOptions.sort((a, b) => a.description.localeCompare(b.description));

    return regionOptions;
  }

  private getRegions() {
    return this.regionService.getRegions().pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  }

  private subscribeToRouteChange() {
    this.getRouteRegionCode$()
      .pipe(
        combineLatestWith(this.regions$),
        map(([regionCode, regions]) => {
          if (isNil(regionCode)) return;

          const region = regions.find((region) => region.regionCode === regionCode);
          return region ?? regions[0];
        }),
        filter((region) => {
          const activeRegion = this.store.selectSnapshot(RegionState.activeRegion);
          return isDefined(region) && region.regionCode !== activeRegion?.regionCode;
        }),
        untilDestroyed(this),
      )
      .subscribe((region) => {
        this.store.dispatch(new SetActiveRegion(region!));
      });
  }

  private getRouteRegionCode$() {
    return this.router.events.pipe(
      filter((data) => data instanceof NavigationEnd),
      map(() => this.getRegionCodeFromRoute()),
    );
  }

  private getRegionCodeFromRoute() {
    const getRegionCode = (route: ActivatedRouteSnapshot): string | null => {
      const regionCode = route.paramMap.get('regionCode');

      if (isDefined(regionCode)) return regionCode;
      if (isNil(route.firstChild)) return null;

      return getRegionCode(route.firstChild);
    };

    const route = this.router.routerState.snapshot.root;
    return getRegionCode(route);
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { defer, finalize, NEVER, share } from 'rxjs';
import { SetLoading } from './layout.actions';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(private store: Store) {}

  /**
   * Use this observable to handle concurrent http requests
   * based on https://github.com/roseyda/test-spinner
   */
  public readonly loader$ = defer(() => {
    this.showLoader();
    return NEVER.pipe(
      finalize(() => {
        this.hideLoader();
      }),
    );
  }).pipe(share());

  showLoader() {
    this.store.dispatch(new SetLoading(true));
  }

  hideLoader() {
    this.store.dispatch(new SetLoading(false));
  }
}
